/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 28/03/2023.
 */
import { DatePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import { FormLabel } from './FormLabel'
import dayjs from 'dayjs'

//const format = 'HH:mm'

export const FormDate = ({ data, formHook }) => {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = formHook

  const errorState = errors[data.id]
  const [, setState] = useState<string>()
  const errorMessage = errorState && errorState.message ? errorState.message : data.errorMsg

  console.log('FormDate > errorState = ', errorState)

  useEffect(() => {
    register(data.id, data.validation)
  }, [register, data.id, data.validation])

  useEffect(() => {
    //console.log('FormDate > data = ', data)

    setValue(data.id, data.value)
  }, [data.id, data.validation, setValue, data.value])

  const handleChange = (value, time) => {
    // the set state is nesscery to get the component to update
    setState(time)
    setValue(data.id, time)
  }

  const currentValue = getValues(data.id) ? dayjs(getValues(data.id)) : dayjs()

  return (
    <div className={` ${data.className} py-2`}>
      <FormLabel label={data.label}>
        {data.postfix ? (
          <p>
            {data.label} <span className="opacity-75 p-small">{data.postfix}</span>
          </p>
        ) : null}
      </FormLabel>
      <DatePicker
        status={errorState ? 'error' : undefined}
        className="w-100"
        placeholder={data.placeholder}
        onChange={handleChange}
        value={currentValue}
      />
      {/*<TimePicker
        className="w-100"
        placeholder={data.placeholder}
        format={format}
        minuteStep={15}
        showNow={false}
        onChange={handleChange}
      />*/}
      {errorState ? <div className="p-small text-danger">{errorMessage}</div> : null}
    </div>
  )
}
