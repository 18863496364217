import { API_URL } from '../constants'

export const ENDPOINTS = {
  PROJECTS: {
    get: API_URL + '/projects',
  },

  ADMIN: {
    WEBHOOK: {
      get: (projectId: string) => {
        return API_URL + `/admin/projects/${projectId}/webhook`
      },
      getById: (projectId: string, hookId: string) => {
        return API_URL + `/admin/projects/${projectId}/webhook/${hookId}`
      },

      ACTION: {
        crud: (projectId: string) => {
          return API_URL + `/admin/projects/${projectId}/webhook`
        },
        testHook: (projectId: string) => {
          return API_URL + `/admin/projects/${projectId}/webhook/test-hook`
        },
      },
    },
    CLIENTS: {
      get: API_URL + '/admin/clients',
      byProjectId: API_URL + '/admin/clients/projects',
      invite: (clientId: string) => {
        return API_URL + '/admin/clients/invite/' + clientId
      },
    },
    PARTICIPANTS: {
      get: (projectId: string) => {
        return API_URL + `/admin/projects/${projectId}/participants`
      },
      ACTION: {
        deleteUser: (projectId: string) => {
          return API_URL + `/admin/projects/${projectId}/participants/delete`
        },
        downloadCsv: (projectId: string) => {
          return API_URL + `/admin/projects/${projectId}/participants/download`
        },
      },
    },
    PROJECTS: {
      imageUpload: (projectId: string) => {
        return API_URL + `/admin/projects/${projectId}/upload`
      },
      update: (projectId: string) => {
        return API_URL + `/admin/projects/${projectId}`
      },
      updateDetails: (projectId: string) => {
        return API_URL + `/admin/projects/${projectId}/project-details`
      },
      create: (projectId: string) => {
        return API_URL + `/admin/projects/${projectId}`
      },
    },
  },
}
