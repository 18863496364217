/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 02/02/2025.
 */
import { CloseCircleFilled } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { FormDataType } from './FormTypes'
import { CRUD_VIEW_TYPE } from '../../Types'
import { FormLabel } from './FormLabel'
import { get, UseFormReturn } from 'react-hook-form'
import { Button, Input } from 'antd'

type Props = {
  data: FormDataType
  formHook: UseFormReturn
  viewType?: CRUD_VIEW_TYPE
}

const questionItemObj = {
  title: '',
  placeholder: '',
  warnTxt: '',
  options: [],
}

const oldFields = [
  'dropDownOptionFive',
  'dropDownOptionFour',
  'dropDownOptionOne',
  'dropDownOptionSix',
  'dropDownOptionThree',
  'dropDownOptionTwo',
  'dropDownTitle',
]

export const QuestionBuilder = ({ data, formHook, viewType }: Props) => {
  const {
    register,
    setValue,
    unregister,
    formState: { errors },
    getValues,
  } = formHook

  console.log('QuestionBuilder > data = ', data)

  const registrationValues = getValues('registration') || {}

  const checkOldOptions =
    oldFields.filter((key, index) => {
      const value = registrationValues[key] || ''
      return value
    }) || []

  const hasOldOptions = checkOldOptions.length > 0

  const [questions, setQuestions] = useState(data.value ? (data.value as any[]) : [])

  const errorState = get(errors, data.id)
  const errorMessage = errorState && errorState.message ? (errorState.message as string) : (data.errorMsg as string)

  useEffect(() => {
    register(data.id, { value: questions })
  }, [register, unregister, data.id, data.validation, questions])

  useEffect(() => {
    return () => {
      unregister(data.id)
    }
  }, [data.id, unregister])

  const addItem = () => {
    const defaultObjCopy = { ...questionItemObj }
    const arr = [...questions, defaultObjCopy]
    setQuestions(arr)
    setValue(data.id, arr)
  }

  return (
    <div className={` ${data.className} py-2 `}>
      {hasOldOptions ? (
        <div className="px-4 py-5 rounded-1 bg-danger-subtle">
          <h5>Detected the use of legacy options</h5>
          <p>
            Do not use both the "Drop down question" and the <b>Questions</b>. If both options are used, the "Drop down
            question" will be used.
          </p>
        </div>
      ) : null}

      <div className="mt-2 d-flex justify-content-between">
        <FormLabel label={data.label}>
          {data.postfix ? (
            <p>
              {data.label} <span className="opacity-75 p-small">{data.postfix}</span>
            </p>
          ) : null}
        </FormLabel>

        <Button type="primary" onClick={addItem}>
          Add new question
        </Button>
      </div>

      <div className="mt-2">
        {questions.map((question, index) => {
          return (
            <QuestionItem key={index} dataId={data.id} index={index} data={question} formHook={formHook}></QuestionItem>
          )
        })}
      </div>

      {errorState ? <div className="p-small text-danger">{errorMessage}</div> : null}
    </div>
  )
}

const QuestionItem = ({ data, dataId, formHook, index }) => {
  const [title, setTitle] = useState(data.title)
  const [placeholder, setPlaceholder] = useState(data.placeholder)
  const [warnTxt, setWarnTxt] = useState(data.warnTxt)

  const [options, setOption] = useState<any[]>(data.options || [])

  const { setValue, unregister } = formHook

  const addAnswerOption = () => {
    const arr = [...options, { title: '' }]
    //const str = dataId + '.' + index + `.options ${}`
    const str = `${dataId}.${index}.options`

    //setValue(dataId + '.' + index + '.' + 'options', arr)
    setValue(str, arr)
    setOption(arr)
  }

  const onChange = (e) => {
    const str = `${dataId}.${index}.title`

    setValue(str, e.target.value)
    setTitle(e.target.value)
  }

  const onChangePlaceholder = (e) => {
    const str = `${dataId}.${index}.placeholder`

    setValue(str, e.target.value)
    setPlaceholder(e.target.value)
  }

  const onChangeWarnTxt = (e) => {
    const str = `${dataId}.${index}.warnTxt`

    setValue(str, e.target.value)
    setWarnTxt(e.target.value)
  }

  const onOptionChange = (e, optionIndex) => {
    //const str = dataId + '.' + index + '.options.' + optionIndex + '.title'

    const str = `${dataId}.${index}.options.${optionIndex}.title`

    setValue(str, e.target.value)

    const arr = [...options]
    arr[optionIndex] = { title: e.target.value }
    setOption(arr)
  }

  const deleteOption = (optionIndex) => {
    console.log('QuestionBuilder > asdasd = ', optionIndex)

    options.forEach((option, optionIndex) => {
      const str = dataId + '.' + index + '.options.' + optionIndex + '.title'
      unregister(str)
    })

    /* options.forEach((option, optionIndex) => {
      const str = dataId + '.' + index + '.options.' + optionIndex + '.title'
      setValue(str)
    })*/

    //unregister("yourDetails")

    const arr = [...options]
    arr.splice(optionIndex, 1)
    //setValue(dataId + '.' + index + '.' + 'options', arr)
    setOption(arr)
    const str = `${dataId}.${index}.options`
    setValue(str, arr)
    //setValue(dataId + '.' + index + '.' + 'options', arr)
  }

  return (
    <div
      className="mb-3 rounded-2"
      style={{ border: '2px solid rgba(0,0,0, .1)', boxShadow: '0px 6px 8px -5px rgba(0, 0, 0, 0.1)' }}
    >
      {/*<FormLabel label={'Question title'}></FormLabel>*/}
      <div className="px-3 py-4" style={{ background: 'rgba(0,0,0, .07)' }}>
        <p className="pb-1">Question title</p>
        <Input placeholder={''} onChange={onChange} defaultValue={title} />

        <div className="mt-2">
          <FormLabel label={'Answer placeholder'}></FormLabel>

          <Input
            placeholder={'Text shown in dropdown before selection is made'}
            onChange={onChangePlaceholder}
            defaultValue={placeholder}
          />
        </div>

        <div className="mt-2">
          <FormLabel label={'Warning text'}></FormLabel>

          <Input placeholder={'Text shown if item is not selected'} onChange={onChangeWarnTxt} defaultValue={warnTxt} />
        </div>
      </div>

      {options.length > 0 && <hr className="my-0" />}

      <div className="px-5 pt-3 pb-1">
        {options.map((option, optionIndex) => {
          return (
            <div key={optionIndex} className="mb-3 ">
              <FormLabel label={'Option ' + (optionIndex + 1)}></FormLabel>
              <div className="d-flex">
                <Input
                  value={option.title}
                  placeholder={data.placeholder}
                  onChange={(e) => {
                    onOptionChange(e, optionIndex)
                  }}
                  defaultValue={''}
                />
                <div
                  className="mt-1 ps-3 pointer"
                  onClick={() => {
                    deleteOption(optionIndex)
                  }}
                >
                  <CloseCircleFilled />
                </div>
              </div>
            </div>
          )
          /*return <QuestionOption key={index} data={option}></QuestionOption>*/
        })}
      </div>

      {/*{options.length > 0 && <hr className="my-0" />}*/}
      <div className="pb-4 pe-5 d-flex justify-content-end">
        <Button type="default" className="" onClick={addAnswerOption}>
          Add new option
        </Button>
      </div>
    </div>
  )
}
